#login-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: #1a1a1a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #a3c7f0;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    color: #a3c7f0;
}

input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #333;
    color: #a3c7f0;
}

.btn {
    background: #0d7377;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    margin-top: 20px;
}

.btn:hover {
    background: #14ffec;
}

.register-btn {
    background: #0d7377;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    margin-top: 10px;
}

.register-btn:hover {
    background: #14ffec;
}

.error-message {
    color: #ff4d4d;
    margin-top: 20px;
}
