body {
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom, #051414, #092727, #0d7377);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #a3c7f0;
    text-align: center;
  }
  
  .container {
    background: rgb(39, 38, 38);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h1{
    color: #a3c7f0;
    margin-bottom: 20px;
  }
  h2 {
    color: #a3c7f0;
    margin-bottom: 20px;
  }
  
  .logout-btn {
    background: #0d7377;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
  }
  
  .logout-btn:hover {
    background: #14ffec;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    color: #a3c7f0;
  }
  
  input, select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #333;
    color: #a3c7f0;
    box-sizing: border-box;
  }
  
  .btn {
    background: #0d7377;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    margin-top: 20px;
  }
  
  .btn:hover {
    background: #14ffec;
  }
  
  .btn:disabled {
    background: #0d7377;
    cursor: not-allowed;
  }
  
  .error-message, .success-message {
    margin-top: 20px;
  }
  
  .error-message {
    color: #ff4d4d;
  }
  
  .success-message {
    color: #4caf50;
  }
  
  #map {
    height: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
      max-width: 100%;
    }
  
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logout-btn {
      width: 30%;
      margin-top: 10px;
    }
  
    #map {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .btn, .logout-btn {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    input, select {
      padding: 8px;
    }
  
    h1 {
      font-size: 24px;
    }
    h2 {
        font-size: 17px;
      }
  
    #map {
      height: 150px;
    }
  }

  .check-location-btn {
    background: #0d7377;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    margin-top: 20px;
  }
  
  .check-location-btn:hover {
    background: #14ffec;
  }
  
  .check-location-btn:disabled {
    background: #0d7377;
    cursor: not-allowed;
  }
  