.form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

label {
    margin-right: 10px;
    color: #555;
    flex: 1;
}

select {
    flex: 2;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}
